import { NgModule } from '@angular/core';
import { NgForOf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { FooterComponent } from '@ui/footer/footer.component';
import { FooterLinkComponent } from '@ui/footer/footer-link/footer-link.component';

@NgModule({
	declarations: [FooterComponent, FooterLinkComponent],
	imports: [NgForOf, RouterLink],
	exports: [FooterComponent],
})
export class FooterModule {}
