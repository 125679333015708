import { Component, Input } from '@angular/core';

export interface FooterLink {
	name: string;
	link: string;
}

@Component({
	selector: 'tk-footer-link',
	templateUrl: './footer-link.component.html',
	styleUrl: './footer-link.component.sass',
})
export class FooterLinkComponent {
	@Input({ required: true }) links: FooterLink[] = [];
}
