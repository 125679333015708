import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HeaderTheme } from '@models/enums/header-theme.enum';
import { FooterLink } from '@ui/footer/footer-link/footer-link.component';

@Component({
	selector: 'tk-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
	@Input() theme: HeaderTheme = HeaderTheme.DARK;

	type = HeaderTheme;
	linksItems: FooterLink[][] = [
		[
			{
				name: 'ВКонтакте',
				link: 'https://vk.com/technokratos',
			},
			{
				name: 'Хабр',
				link: 'https://habr.com/ru/users/technokratiya/posts/',
			},
		],
		[
			{
				name: 'Ютуб',
				link: 'https://www.youtube.com/channel/UC6DHo_ryop2YVIwifdFvuRw',
			},
			{
				name: 'Телеграм',
				link: 'https://t.me/technokratos',
			},
		],
		[
			{
				name: 'ВиСи',
				link: 'https://vc.ru/u/482480-tehnokratiya',
			},
			{
				name: 'Контакты',
				link: '/contacts',
			},
		],
	];
}
