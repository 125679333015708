<footer class="footer">
	<div class="footer__contacts">
		<a class="footer-contacts__email" href="mailto:sales@technokratos.com">sales&#64;technokratos.com</a>
		<address class="footer-contacts__address">Казань, Кремлёвская,&nbsp;21</address>
	</div>
	<div class="footer__row">
		<div>
			<a routerLink="/privacy-policy" target="_blank" class="footer-row__privacy-policy">
				Политика обработки персональных данных
			</a>
			<p class="footer-row__company">© ООО «Технократия»</p>
		</div>
		<div class="footer-row__list">
			<tk-footer-link *ngFor="let links of linksItems" [links]="links" />
		</div>
	</div>
</footer>
